@import url(font-awesome.min.css);


@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');




/* Reset */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote {
  &:before, &:after {
    content: '';
    content: none;
  }
}

q {
  &:before, &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

/* Box Model */

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  &:before, &:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

/* Grid */

.row {
  border-bottom: solid 1px transparent;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  > * {
    float: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  &:after, &:before {
    content: '';
    display: block;
    clear: both;
    height: 0;
  }
  &.uniform > * > {
    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
  &.\30 \25 {
    > * {
      padding: 0 0 0 0em;
    }
    margin: 0 0 -1px 0em;
  }
  &.uniform {
    &.\30 \25 {
      > * {
        padding: 0em 0 0 0em;
      }
      margin: 0em 0 -1px 0em;
    }
    > * {
      padding: 1.75em 0 0 1.75em;
    }
    margin: -1.75em 0 -1px -1.75em;
  }
  > * {
    padding: 0 0 0 1.75em;
  }
  margin: 0 0 -1px -1.75em;
  &.\32 00\25 {
    > * {
      padding: 0 0 0 3.5em;
    }
    margin: 0 0 -1px -3.5em;
  }
  &.uniform.\32 00\25 {
    > * {
      padding: 3.5em 0 0 3.5em;
    }
    margin: -3.5em 0 -1px -3.5em;
  }
  &.\31 50\25 {
    > * {
      padding: 0 0 0 2.625em;
    }
    margin: 0 0 -1px -2.625em;
  }
  &.uniform.\31 50\25 {
    > * {
      padding: 2.625em 0 0 2.625em;
    }
    margin: -2.625em 0 -1px -2.625em;
  }
  &.\35 0\25 {
    > * {
      padding: 0 0 0 0.875em;
    }
    margin: 0 0 -1px -0.875em;
  }
  &.uniform.\35 0\25 {
    > * {
      padding: 0.875em 0 0 0.875em;
    }
    margin: -0.875em 0 -1px -0.875em;
  }
  &.\32 5\25 {
    > * {
      padding: 0 0 0 0.4375em;
    }
    margin: 0 0 -1px -0.4375em;
  }
  &.uniform.\32 5\25 {
    > * {
      padding: 0.4375em 0 0 0.4375em;
    }
    margin: -0.4375em 0 -1px -0.4375em;
  }
}

.\31 2u, .\31 2u\24 {
  width: 100%;
  clear: none;
  margin-left: 0;
}

.\31 1u, .\31 1u\24 {
  width: 91.6666666667%;
  clear: none;
  margin-left: 0;
}

.\31 0u, .\31 0u\24 {
  width: 83.3333333333%;
  clear: none;
  margin-left: 0;
}

.\39 u, .\39 u\24 {
  width: 75%;
  clear: none;
  margin-left: 0;
}

.\38 u, .\38 u\24 {
  width: 66.6666666667%;
  clear: none;
  margin-left: 0;
}

.\37 u, .\37 u\24 {
  width: 58.3333333333%;
  clear: none;
  margin-left: 0;
}

.\36 u, .\36 u\24 {
  width: 50%;
  clear: none;
  margin-left: 0;
}

.\35 u, .\35 u\24 {
  width: 41.6666666667%;
  clear: none;
  margin-left: 0;
}

.\34 u, .\34 u\24 {
  width: 33.3333333333%;
  clear: none;
  margin-left: 0;
}

.\33 u, .\33 u\24 {
  width: 25%;
  clear: none;
  margin-left: 0;
}

.\32 u, .\32 u\24 {
  width: 16.6666666667%;
  clear: none;
  margin-left: 0;
}

.\31 u, .\31 u\24 {
  width: 8.3333333333%;
  clear: none;
  margin-left: 0;
}

.\31 2u\24  + *, .\31 1u\24  + *, .\31 0u\24  + *, .\39 u\24  + *, .\38 u\24  + *, .\37 u\24  + *, .\36 u\24  + *, .\35 u\24  + *, .\34 u\24  + *, .\33 u\24  + *, .\32 u\24  + *, .\31 u\24  + * {
  clear: left;
}

.\-11u {
  margin-left: 91.66667%;
}

.\-10u {
  margin-left: 83.33333%;
}

.\-9u {
  margin-left: 75%;
}

.\-8u {
  margin-left: 66.66667%;
}

.\-7u {
  margin-left: 58.33333%;
}

.\-6u {
  margin-left: 50%;
}

.\-5u {
  margin-left: 41.66667%;
}

.\-4u {
  margin-left: 33.33333%;
}

.\-3u {
  margin-left: 25%;
}

.\-2u {
  margin-left: 16.66667%;
}

.\-1u {
  margin-left: 8.33333%;
}

@media screen and (max-width: 1680px) {
  .main-img {
    /*left: -160px !important;*/
    background-image: url(../images/screenshots/lolo_kalendarz1024.png) !important;
    width: 1024px !important;
    height: 567px !important;
    left: -40px !important;
  }
  .row {
    > * {
      padding: 0 0 0 1.75em;
    }
    margin: 0 0 -1px -1.75em;
    &.uniform {
      > * {
        padding: 1.75em 0 0 1.75em;
      }
      margin: -1.75em 0 -1px -1.75em;
    }
    &.\32 00\25 {
      > * {
        padding: 0 0 0 3.5em;
      }
      margin: 0 0 -1px -3.5em;
    }
    &.uniform.\32 00\25 {
      > * {
        padding: 3.5em 0 0 3.5em;
      }
      margin: -3.5em 0 -1px -3.5em;
    }
    &.\31 50\25 {
      > * {
        padding: 0 0 0 2.625em;
      }
      margin: 0 0 -1px -2.625em;
    }
    &.uniform.\31 50\25 {
      > * {
        padding: 2.625em 0 0 2.625em;
      }
      margin: -2.625em 0 -1px -2.625em;
    }
    &.\35 0\25 {
      > * {
        padding: 0 0 0 0.875em;
      }
      margin: 0 0 -1px -0.875em;
    }
    &.uniform.\35 0\25 {
      > * {
        padding: 0.875em 0 0 0.875em;
      }
      margin: -0.875em 0 -1px -0.875em;
    }
    &.\32 5\25 {
      > * {
        padding: 0 0 0 0.4375em;
      }
      margin: 0 0 -1px -0.4375em;
    }
    &.uniform.\32 5\25 {
      > * {
        padding: 0.4375em 0 0 0.4375em;
      }
      margin: -0.4375em 0 -1px -0.4375em;
    }
  }
  .\31 2u\28xlarge\29, .\31 2u\24\28xlarge\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28xlarge\29, .\31 1u\24\28xlarge\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28xlarge\29, .\31 0u\24\28xlarge\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28xlarge\29, .\39 u\24\28xlarge\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28xlarge\29, .\38 u\24\28xlarge\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28xlarge\29, .\37 u\24\28xlarge\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28xlarge\29, .\36 u\24\28xlarge\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28xlarge\29, .\35 u\24\28xlarge\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28xlarge\29, .\34 u\24\28xlarge\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28xlarge\29, .\33 u\24\28xlarge\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28xlarge\29, .\32 u\24\28xlarge\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28xlarge\29, .\31 u\24\28xlarge\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28xlarge\29 + *, .\31 1u\24\28xlarge\29 + *, .\31 0u\24\28xlarge\29 + *, .\39 u\24\28xlarge\29 + *, .\38 u\24\28xlarge\29 + *, .\37 u\24\28xlarge\29 + *, .\36 u\24\28xlarge\29 + *, .\35 u\24\28xlarge\29 + *, .\34 u\24\28xlarge\29 + *, .\33 u\24\28xlarge\29 + *, .\32 u\24\28xlarge\29 + *, .\31 u\24\28xlarge\29 + * {
    clear: left;
  }
  .\-11u\28xlarge\29 {
    margin-left: 91.66667%;
  }
  .\-10u\28xlarge\29 {
    margin-left: 83.33333%;
  }
  .\-9u\28xlarge\29 {
    margin-left: 75%;
  }
  .\-8u\28xlarge\29 {
    margin-left: 66.66667%;
  }
  .\-7u\28xlarge\29 {
    margin-left: 58.33333%;
  }
  .\-6u\28xlarge\29 {
    margin-left: 50%;
  }
  .\-5u\28xlarge\29 {
    margin-left: 41.66667%;
  }
  .\-4u\28xlarge\29 {
    margin-left: 33.33333%;
  }
  .\-3u\28xlarge\29 {
    margin-left: 25%;
  }
  .\-2u\28xlarge\29 {
    margin-left: 16.66667%;
  }
  .\-1u\28xlarge\29 {
    margin-left: 8.33333%;
  }
}

@media screen and (max-width: 1280px) {
  .style1 {
    margin-top: 17em !important;
  }
  .row {
    > * {
      padding: 0 0 0 1.75em;
    }
    margin: 0 0 -1px -1.75em;
    &.uniform {
      > * {
        padding: 1.75em 0 0 1.75em;
      }
      margin: -1.75em 0 -1px -1.75em;
    }
    &.\32 00\25 {
      > * {
        padding: 0 0 0 3.5em;
      }
      margin: 0 0 -1px -3.5em;
    }
    &.uniform.\32 00\25 {
      > * {
        padding: 3.5em 0 0 3.5em;
      }
      margin: -3.5em 0 -1px -3.5em;
    }
    &.\31 50\25 {
      > * {
        padding: 0 0 0 2.625em;
      }
      margin: 0 0 -1px -2.625em;
    }
    &.uniform.\31 50\25 {
      > * {
        padding: 2.625em 0 0 2.625em;
      }
      margin: -2.625em 0 -1px -2.625em;
    }
    &.\35 0\25 {
      > * {
        padding: 0 0 0 0.875em;
      }
      margin: 0 0 -1px -0.875em;
    }
    &.uniform.\35 0\25 {
      > * {
        padding: 0.875em 0 0 0.875em;
      }
      margin: -0.875em 0 -1px -0.875em;
    }
    &.\32 5\25 {
      > * {
        padding: 0 0 0 0.4375em;
      }
      margin: 0 0 -1px -0.4375em;
    }
    &.uniform.\32 5\25 {
      > * {
        padding: 0.4375em 0 0 0.4375em;
      }
      margin: -0.4375em 0 -1px -0.4375em;
    }
  }
  .\31 2u\28large\29, .\31 2u\24\28large\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28large\29, .\31 1u\24\28large\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28large\29, .\31 0u\24\28large\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28large\29, .\39 u\24\28large\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28large\29, .\38 u\24\28large\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28large\29, .\37 u\24\28large\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28large\29, .\36 u\24\28large\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28large\29, .\35 u\24\28large\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28large\29, .\34 u\24\28large\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28large\29, .\33 u\24\28large\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28large\29, .\32 u\24\28large\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28large\29, .\31 u\24\28large\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28large\29 + *, .\31 1u\24\28large\29 + *, .\31 0u\24\28large\29 + *, .\39 u\24\28large\29 + *, .\38 u\24\28large\29 + *, .\37 u\24\28large\29 + *, .\36 u\24\28large\29 + *, .\35 u\24\28large\29 + *, .\34 u\24\28large\29 + *, .\33 u\24\28large\29 + *, .\32 u\24\28large\29 + *, .\31 u\24\28large\29 + * {
    clear: left;
  }
  .\-11u\28large\29 {
    margin-left: 91.66667%;
  }
  .\-10u\28large\29 {
    margin-left: 83.33333%;
  }
  .\-9u\28large\29 {
    margin-left: 75%;
  }
  .\-8u\28large\29 {
    margin-left: 66.66667%;
  }
  .\-7u\28large\29 {
    margin-left: 58.33333%;
  }
  .\-6u\28large\29 {
    margin-left: 50%;
  }
  .\-5u\28large\29 {
    margin-left: 41.66667%;
  }
  .\-4u\28large\29 {
    margin-left: 33.33333%;
  }
  .\-3u\28large\29 {
    margin-left: 25%;
  }
  .\-2u\28large\29 {
    margin-left: 16.66667%;
  }
  .\-1u\28large\29 {
    margin-left: 8.33333%;
  }
}

@media (max-width: 1124px) {
  .main-img {
    background-image: url(../images/screenshots/lolo_kalendarz768.png) !important;
    width: 768px !important;
    height: 425px !important;
    left: 52px !important;
  }
}

@media screen and (max-width: 980px) {
  .style1 {
    margin-top: 9em !important;
  }
  .row {
    > * {
      padding: 0 0 0 1.75em;
    }
    margin: 0 0 -1px -1.75em;
    &.uniform {
      > * {
        padding: 1.75em 0 0 1.75em;
      }
      margin: -1.75em 0 -1px -1.75em;
    }
    &.\32 00\25 {
      > * {
        padding: 0 0 0 3.5em;
      }
      margin: 0 0 -1px -3.5em;
    }
    &.uniform.\32 00\25 {
      > * {
        padding: 3.5em 0 0 3.5em;
      }
      margin: -3.5em 0 -1px -3.5em;
    }
    &.\31 50\25 {
      > * {
        padding: 0 0 0 2.625em;
      }
      margin: 0 0 -1px -2.625em;
    }
    &.uniform.\31 50\25 {
      > * {
        padding: 2.625em 0 0 2.625em;
      }
      margin: -2.625em 0 -1px -2.625em;
    }
    &.\35 0\25 {
      > * {
        padding: 0 0 0 0.875em;
      }
      margin: 0 0 -1px -0.875em;
    }
    &.uniform.\35 0\25 {
      > * {
        padding: 0.875em 0 0 0.875em;
      }
      margin: -0.875em 0 -1px -0.875em;
    }
    &.\32 5\25 {
      > * {
        padding: 0 0 0 0.4375em;
      }
      margin: 0 0 -1px -0.4375em;
    }
    &.uniform.\32 5\25 {
      > * {
        padding: 0.4375em 0 0 0.4375em;
      }
      margin: -0.4375em 0 -1px -0.4375em;
    }
  }
  .\31 2u\28medium\29, .\31 2u\24\28medium\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28medium\29, .\31 1u\24\28medium\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28medium\29, .\31 0u\24\28medium\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28medium\29, .\39 u\24\28medium\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28medium\29, .\38 u\24\28medium\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28medium\29, .\37 u\24\28medium\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28medium\29, .\36 u\24\28medium\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28medium\29, .\35 u\24\28medium\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28medium\29, .\34 u\24\28medium\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28medium\29, .\33 u\24\28medium\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28medium\29, .\32 u\24\28medium\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28medium\29, .\31 u\24\28medium\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28medium\29 + *, .\31 1u\24\28medium\29 + *, .\31 0u\24\28medium\29 + *, .\39 u\24\28medium\29 + *, .\38 u\24\28medium\29 + *, .\37 u\24\28medium\29 + *, .\36 u\24\28medium\29 + *, .\35 u\24\28medium\29 + *, .\34 u\24\28medium\29 + *, .\33 u\24\28medium\29 + *, .\32 u\24\28medium\29 + *, .\31 u\24\28medium\29 + * {
    clear: left;
  }
  .\-11u\28medium\29 {
    margin-left: 91.66667%;
  }
  .\-10u\28medium\29 {
    margin-left: 83.33333%;
  }
  .\-9u\28medium\29 {
    margin-left: 75%;
  }
  .\-8u\28medium\29 {
    margin-left: 66.66667%;
  }
  .\-7u\28medium\29 {
    margin-left: 58.33333%;
  }
  .\-6u\28medium\29 {
    margin-left: 50%;
  }
  .\-5u\28medium\29 {
    margin-left: 41.66667%;
  }
  .\-4u\28medium\29 {
    margin-left: 33.33333%;
  }
  .\-3u\28medium\29 {
    margin-left: 25%;
  }
  .\-2u\28medium\29 {
    margin-left: 16.66667%;
  }
  .\-1u\28medium\29 {
    margin-left: 8.33333%;
  }
}

@media (max-width: 901px) {
  .main-img {
    left: 5px !important;
  }
}

@media (max-width: 840px) {
  #banner .inner {
    top: -155px !important;
  }
  .main-img {
    background-image: url(../images/screenshots/lolo_kalendarz1024.png) !important;
    width: 550px !important;
    height: 304px !important;
    left: 90px !important;
    h3 {
      font-size: 19px !important;
    }
  }
  .style1 {
    margin-top: 3em !important;
  }
}

@media screen and (max-width: 736px) {
  .main-img {
    left: 35px !important;
  }
  .row {
    > * {
      padding: 0 0 0 1.25em;
    }
    margin: 0 0 -1px -1.25em;
    &.uniform {
      > * {
        padding: 1.25em 0 0 1.25em;
      }
      margin: -1.25em 0 -1px -1.25em;
    }
    &.\32 00\25 {
      > * {
        padding: 0 0 0 2.5em;
      }
      margin: 0 0 -1px -2.5em;
    }
    &.uniform.\32 00\25 {
      > * {
        padding: 2.5em 0 0 2.5em;
      }
      margin: -2.5em 0 -1px -2.5em;
    }
    &.\31 50\25 {
      > * {
        padding: 0 0 0 1.875em;
      }
      margin: 0 0 -1px -1.875em;
    }
    &.uniform.\31 50\25 {
      > * {
        padding: 1.875em 0 0 1.875em;
      }
      margin: -1.875em 0 -1px -1.875em;
    }
    &.\35 0\25 {
      > * {
        padding: 0 0 0 0.625em;
      }
      margin: 0 0 -1px -0.625em;
    }
    &.uniform.\35 0\25 {
      > * {
        padding: 0.625em 0 0 0.625em;
      }
      margin: -0.625em 0 -1px -0.625em;
    }
    &.\32 5\25 {
      > * {
        padding: 0 0 0 0.3125em;
      }
      margin: 0 0 -1px -0.3125em;
    }
    &.uniform.\32 5\25 {
      > * {
        padding: 0.3125em 0 0 0.3125em;
      }
      margin: -0.3125em 0 -1px -0.3125em;
    }
  }
  .\31 2u\28small\29, .\31 2u\24\28small\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28small\29, .\31 1u\24\28small\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28small\29, .\31 0u\24\28small\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28small\29, .\39 u\24\28small\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28small\29, .\38 u\24\28small\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28small\29, .\37 u\24\28small\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28small\29, .\36 u\24\28small\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28small\29, .\35 u\24\28small\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28small\29, .\34 u\24\28small\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28small\29, .\33 u\24\28small\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28small\29, .\32 u\24\28small\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28small\29, .\31 u\24\28small\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28small\29 + *, .\31 1u\24\28small\29 + *, .\31 0u\24\28small\29 + *, .\39 u\24\28small\29 + *, .\38 u\24\28small\29 + *, .\37 u\24\28small\29 + *, .\36 u\24\28small\29 + *, .\35 u\24\28small\29 + *, .\34 u\24\28small\29 + *, .\33 u\24\28small\29 + *, .\32 u\24\28small\29 + *, .\31 u\24\28small\29 + * {
    clear: left;
  }
  .\-11u\28small\29 {
    margin-left: 91.66667%;
  }
  .\-10u\28small\29 {
    margin-left: 83.33333%;
  }
  .\-9u\28small\29 {
    margin-left: 75%;
  }
  .\-8u\28small\29 {
    margin-left: 66.66667%;
  }
  .\-7u\28small\29 {
    margin-left: 58.33333%;
  }
  .\-6u\28small\29 {
    margin-left: 50%;
  }
  .\-5u\28small\29 {
    margin-left: 41.66667%;
  }
  .\-4u\28small\29 {
    margin-left: 33.33333%;
  }
  .\-3u\28small\29 {
    margin-left: 25%;
  }
  .\-2u\28small\29 {
    margin-left: 16.66667%;
  }
  .\-1u\28small\29 {
    margin-left: 8.33333%;
  }
}

.small-text {
  display: none;
}

@media(max-width: 560px) {
 #banner .small-text {
  display: block;
  color: #fff;
}
}

@media screen and (max-width: 640px) {
  #banner .inner {
    top: -75px !important;
  }
  .main-title {
    top: -135px !important;
  }
  .main-img {
    background-image: url(../images/screenshots/lolo_kalendarz400.png) !important;
    width: 400px !important;
    height: 221px !important;
    left: 90px !important;
    h3 {
      font-size: 20px !important;
      top: -120px !important;
    }
  }
  .row {
    > * {
      padding: 0 0 0 1.25em;
    }
    margin: 0 0 -1px -1.25em;
    &.uniform {
      > * {
        padding: 1.25em 0 0 1.25em;
      }
      margin: -1.25em 0 -1px -1.25em;
    }
    &.\32 00\25 {
      > * {
        padding: 0 0 0 2.5em;
      }
      margin: 0 0 -1px -2.5em;
    }
    &.uniform.\32 00\25 {
      > * {
        padding: 2.5em 0 0 2.5em;
      }
      margin: -2.5em 0 -1px -2.5em;
    }
    &.\31 50\25 {
      > * {
        padding: 0 0 0 1.875em;
      }
      margin: 0 0 -1px -1.875em;
    }
    &.uniform.\31 50\25 {
      > * {
        padding: 1.875em 0 0 1.875em;
      }
      margin: -1.875em 0 -1px -1.875em;
    }
    &.\35 0\25 {
      > * {
        padding: 0 0 0 0.625em;
      }
      margin: 0 0 -1px -0.625em;
    }
    &.uniform.\35 0\25 {
      > * {
        padding: 0.625em 0 0 0.625em;
      }
      margin: -0.625em 0 -1px -0.625em;
    }
    &.\32 5\25 {
      > * {
        padding: 0 0 0 0.3125em;
      }
      margin: 0 0 -1px -0.3125em;
    }
    &.uniform.\32 5\25 {
      > * {
        padding: 0.3125em 0 0 0.3125em;
      }
      margin: -0.3125em 0 -1px -0.3125em;
    }
  }
  .\31 2u\28xsmall\29, .\31 2u\24\28xsmall\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\31 1u\28xsmall\29, .\31 1u\24\28xsmall\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 0u\28xsmall\29, .\31 0u\24\28xsmall\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39 u\28xsmall\29, .\39 u\24\28xsmall\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38 u\28xsmall\29, .\38 u\24\28xsmall\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37 u\28xsmall\29, .\37 u\24\28xsmall\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36 u\28xsmall\29, .\36 u\24\28xsmall\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35 u\28xsmall\29, .\35 u\24\28xsmall\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34 u\28xsmall\29, .\34 u\24\28xsmall\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33 u\28xsmall\29, .\33 u\24\28xsmall\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32 u\28xsmall\29, .\32 u\24\28xsmall\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31 u\28xsmall\29, .\31 u\24\28xsmall\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\31 2u\24\28xsmall\29 + *, .\31 1u\24\28xsmall\29 + *, .\31 0u\24\28xsmall\29 + *, .\39 u\24\28xsmall\29 + *, .\38 u\24\28xsmall\29 + *, .\37 u\24\28xsmall\29 + *, .\36 u\24\28xsmall\29 + *, .\35 u\24\28xsmall\29 + *, .\34 u\24\28xsmall\29 + *, .\33 u\24\28xsmall\29 + *, .\32 u\24\28xsmall\29 + *, .\31 u\24\28xsmall\29 + * {
    clear: left;
  }
  .\-11u\28xsmall\29 {
    margin-left: 91.66667%;
  }
  .\-10u\28xsmall\29 {
    margin-left: 83.33333%;
  }
  .\-9u\28xsmall\29 {
    margin-left: 75%;
  }
  .\-8u\28xsmall\29 {
    margin-left: 66.66667%;
  }
  .\-7u\28xsmall\29 {
    margin-left: 58.33333%;
  }
  .\-6u\28xsmall\29 {
    margin-left: 50%;
  }
  .\-5u\28xsmall\29 {
    margin-left: 41.66667%;
  }
  .\-4u\28xsmall\29 {
    margin-left: 33.33333%;
  }
  .\-3u\28xsmall\29 {
    margin-left: 25%;
  }
  .\-2u\28xsmall\29 {
    margin-left: 16.66667%;
  }
  .\-1u\28xsmall\29 {
    margin-left: 8.33333%;
  }
}

@media (max-width: 571px) {
  .main-img {
    left: 40px !important;
  }
}

@media (max-width: 560px) {
  #banner .inner {
    top: -190px !important;
  }
}

@media (max-width: 500px) {
  .main-img {
    left: 0px !important;
  }
}

@media (max-width: 452px) {
  .main-img {
    background-image: url(../images/screenshots/lolo_kalendarz330.png) !important;
    width: 330px !important;
    height: 138px !important;
    left: 4px !important;
  }
}

/*@media(max-width: 368px) {
	.inner .main-img {
		background-image: url(../images/screenshots/lolo_kalendarz250.png) !important;
		width: 250px !important;
		height: 221px !important;
		left: 20px !important;
	}
	}*/

  @media (max-width: 368px) {
    .inner .main-img {
      left: 22px !important;
    }
  }

  @media (max-width: 330px) {
    .inner .main-img {
      left: -4px !important;
    }
  }

  @media (max-width: 399px) {
    .main-img {
      left: -12px !important;
    }
  }

  @media screen and (max-width: 360px) {
    .row {
      > * {
        padding: 0 0 0 1.25em;
      }
      margin: 0 0 -1px -1.25em;
      &.uniform {
        > * {
          padding: 1.25em 0 0 1.25em;
        }
        margin: -1.25em 0 -1px -1.25em;
      }
      &.\32 00\25 {
        > * {
          padding: 0 0 0 2.5em;
        }
        margin: 0 0 -1px -2.5em;
      }
      &.uniform.\32 00\25 {
        > * {
          padding: 2.5em 0 0 2.5em;
        }
        margin: -2.5em 0 -1px -2.5em;
      }
      &.\31 50\25 {
        > * {
          padding: 0 0 0 1.875em;
        }
        margin: 0 0 -1px -1.875em;
      }
      &.uniform.\31 50\25 {
        > * {
          padding: 1.875em 0 0 1.875em;
        }
        margin: -1.875em 0 -1px -1.875em;
      }
      &.\35 0\25 {
        > * {
          padding: 0 0 0 0.625em;
        }
        margin: 0 0 -1px -0.625em;
      }
      &.uniform.\35 0\25 {
        > * {
          padding: 0.625em 0 0 0.625em;
        }
        margin: -0.625em 0 -1px -0.625em;
      }
      &.\32 5\25 {
        > * {
          padding: 0 0 0 0.3125em;
        }
        margin: 0 0 -1px -0.3125em;
      }
      &.uniform.\32 5\25 {
        > * {
          padding: 0.3125em 0 0 0.3125em;
        }
        margin: -0.3125em 0 -1px -0.3125em;
      }
    }
    .\31 2u\28xxsmall\29, .\31 2u\24\28xxsmall\29 {
      width: 100%;
      clear: none;
      margin-left: 0;
    }
    .\31 1u\28xxsmall\29, .\31 1u\24\28xxsmall\29 {
      width: 91.6666666667%;
      clear: none;
      margin-left: 0;
    }
    .\31 0u\28xxsmall\29, .\31 0u\24\28xxsmall\29 {
      width: 83.3333333333%;
      clear: none;
      margin-left: 0;
    }
    .\39 u\28xxsmall\29, .\39 u\24\28xxsmall\29 {
      width: 75%;
      clear: none;
      margin-left: 0;
    }
    .\38 u\28xxsmall\29, .\38 u\24\28xxsmall\29 {
      width: 66.6666666667%;
      clear: none;
      margin-left: 0;
    }
    .\37 u\28xxsmall\29, .\37 u\24\28xxsmall\29 {
      width: 58.3333333333%;
      clear: none;
      margin-left: 0;
    }
    .\36 u\28xxsmall\29, .\36 u\24\28xxsmall\29 {
      width: 50%;
      clear: none;
      margin-left: 0;
    }
    .\35 u\28xxsmall\29, .\35 u\24\28xxsmall\29 {
      width: 41.6666666667%;
      clear: none;
      margin-left: 0;
    }
    .\34 u\28xxsmall\29, .\34 u\24\28xxsmall\29 {
      width: 33.3333333333%;
      clear: none;
      margin-left: 0;
    }
    .\33 u\28xxsmall\29, .\33 u\24\28xxsmall\29 {
      width: 25%;
      clear: none;
      margin-left: 0;
    }
    .\32 u\28xxsmall\29, .\32 u\24\28xxsmall\29 {
      width: 16.6666666667%;
      clear: none;
      margin-left: 0;
    }
    .\31 u\28xxsmall\29, .\31 u\24\28xxsmall\29 {
      width: 8.3333333333%;
      clear: none;
      margin-left: 0;
    }
    .\31 2u\24\28xxsmall\29 + *, .\31 1u\24\28xxsmall\29 + *, .\31 0u\24\28xxsmall\29 + *, .\39 u\24\28xxsmall\29 + *, .\38 u\24\28xxsmall\29 + *, .\37 u\24\28xxsmall\29 + *, .\36 u\24\28xxsmall\29 + *, .\35 u\24\28xxsmall\29 + *, .\34 u\24\28xxsmall\29 + *, .\33 u\24\28xxsmall\29 + *, .\32 u\24\28xxsmall\29 + *, .\31 u\24\28xxsmall\29 + * {
      clear: left;
    }
    .\-11u\28xxsmall\29 {
      margin-left: 91.66667%;
    }
    .\-10u\28xxsmall\29 {
      margin-left: 83.33333%;
    }
    .\-9u\28xxsmall\29 {
      margin-left: 75%;
    }
    .\-8u\28xxsmall\29 {
      margin-left: 66.66667%;
    }
    .\-7u\28xxsmall\29 {
      margin-left: 58.33333%;
    }
    .\-6u\28xxsmall\29 {
      margin-left: 50%;
    }
    .\-5u\28xxsmall\29 {
      margin-left: 41.66667%;
    }
    .\-4u\28xxsmall\29 {
      margin-left: 33.33333%;
    }
    .\-3u\28xxsmall\29 {
      margin-left: 25%;
    }
    .\-2u\28xxsmall\29 {
      margin-left: 16.66667%;
    }
    .\-1u\28xxsmall\29 {
      margin-left: 8.33333%;
    }
  }

/* Basic */

@-ms-viewport {
  width: device-width;
}


body {
  -ms-overflow-style: scrollbar;
  background-color: #fff;
  /*background-image: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)), url("../images/bg.jpg");*/
  background-size: auto, cover;
  background-attachment: fixed, fixed;
  background-position: center, center;
  &.is-loading * {
    -moz-animation: none !important;
    -webkit-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
    -moz-transition: none !important;
    -webkit-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
    &:before, &:after {
      -moz-animation: none !important;
      -webkit-animation: none !important;
      -ms-animation: none !important;
      animation: none !important;
      -moz-transition: none !important;
      -webkit-transition: none !important;
      -ms-transition: none !important;
      transition: none !important;
    }
  }
  color: #ffffff;
  /*font-family: "Source Sans Pro", Helvetica, sans-serif;*/
  font-family: 'Open Sans', sans-serif;
  font-size: 16.5pt;
  font-weight: 300;
  line-height: 1.65;
}

@media screen and (max-width: 640px) {
  html, body {
    min-width: 320px;
  }
}

/* Type */

input, select, textarea {
  color: #ffffff;
  /*font-family: "Source Sans Pro", Helvetica, sans-serif;*/
  font-family: 'Open Sans', sans-serif;
  font-size: 16.5pt;
  font-weight: 300;
  line-height: 1.65;
}

@media screen and (max-width: 1680px) {
  body, input, select, textarea {
    font-size: 13pt;
  }
}

@media screen and (max-width: 1280px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}

@media screen and (max-width: 980px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}

@media screen and (max-width: 736px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}

@media screen and (max-width: 640px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}

.menu-ul a {
  border-bottom: transparent;
  color: #fff !important;
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
    border-bottom-color: transparent;
    color: #5a5a5a !important;
    background-color: rgba(232, 240, 247, 0.6392156862745098);
  }
}
a {
  // -moz-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  // -webkit-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  // -ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  // transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  /*border-bottom: dotted 1px rgba(255, 255, 255, 0.35);*/
  border-bottom: transparent;
  color: #3e9cf3 !important;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    // color: #3690c1 !important;
    color: #2296f3 !important;
    // background-color: rgba(232, 240, 247, 0.6392156862745098);
    // border-radius: 10px;
    
  }
  &.special:not(.button) {
    text-decoration: none;
    // border-bottom: 0;
    display: block;
    /*font-family: Raleway, Helvetica, sans-serif;*/
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin: 0 0 2em 0;
    text-transform: uppercase;
    color: #35363a;
    padding: 0 2.25em;
    // &:before {
    //   -moz-osx-font-smoothing: grayscale;
    //   -webkit-font-smoothing: antialiased;
    //   font-family: FontAwesome;
    //   /*font-family: 'Lato', sans-serif;*/
    //   font-style: normal;
    //   font-weight: normal;
    //   text-transform: none !important;
    //   -moz-transition: background-color 0.2s ease-in-out;
    //   -webkit-transition: background-color 0.2s ease-in-out;
    //   -ms-transition: background-color 0.2s ease-in-out;
    //   transition: background-color 0.2s ease-in-out;
    //   border-radius: 100%;
    //   // border: solid 2px rgba(255, 255, 255, 0.125);
    //   border: solid 2px transparent;

    //   content: '\f105';
    //   display: inline-block;
    //   font-size: 1.25em;
    //   height: 2em;
    //   line-height: 1.65em;
    //   /*margin-right: 0.85em;*/
    //   text-align: center;
    //   text-indent: 0.15em;
    //   vertical-align: middle;
    //   width: 2em;
    // }
    &:hover:before {
      background-color: rgba(255, 255, 255, 0.025);
    }
    &:active:before {
      background-color: rgba(255, 255, 255, 0.075);
    }
  }
}

strong, b {
  color: #ffffff;
  font-weight: 600;
}

em, i {
  font-style: italic;
}

p {
  margin: 0 0 2em 0;
  color: #35363a;
  text-align: justify;
}

h1, h2, h3, h4, h5, h6 {
  color: #ffffff;
  /*font-family: Raleway, Helvetica, sans-serif;*/
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  margin: 0 0 1em 0;
  /*text-transform: uppercase;*/
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  text-decoration: none;
}

h1 span, h2 span, h3 span, h4 span, h5 span, h6 span {
  font-weight: 200;
}

h1.major, h2.major, h3.major, h4.major, h5.major, h6.major {
  // padding-bottom: 1em;
  // border-bottom: solid 1px #cacac9;
  color: #000;
}
h1 {
  font-size: 1.6em;
}

h2 {
  font-size: 1.2em;
}

h3 {
  // font-size: 0.8em;
  font-size: 1.4em;
  color: red;
}

h4, h5, h6 {
  font-size: 1em;
}

@media screen and (max-width: 736px) {
  h2 {
    font-size: 1em;
  }
  h3 {
    font-size: 1.2em;
  }
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

blockquote {
  border-left: solid 4px rgba(255, 255, 255, 0.125);
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em;
}

code {
  background: rgba(255, 255, 255, 0.025);
  border-radius: 5px;
  border: solid 2px rgba(255, 255, 255, 0.125);
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0 2em 0;
  code {
    display: block;
    line-height: 1.75em;
    padding: 1em 1.5em;
    overflow-x: auto;
  }
}

hr {
  border: 0;
  border-bottom: solid 2px rgba(255, 255, 255, 0.125);
  margin: 2.5em 0;
  &.major {
    margin: 4em 0;
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Section/Article */

section.special, article.special {
  text-align: center;
}

/* Form */

form {
  margin: 0 0 2em 0;
  .field {
    margin: 0 0 2em 0;
    label {
      margin-top: -1.5em;
    }
    &:first-child label {
      margin-top: 0;
    }
  }
  > :last-child {
    margin-bottom: 0;
  }
}

label {
  color: #35363a;
  display: block;
  /*font-family: Raleway, Helvetica, sans-serif;*/
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8em;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin: 0 0 0.7em 0;
  text-transform: uppercase;
}

input {
  &[type="text"], &[type="password"], &[type="email"], &[type="tel"] {
    /*-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;*/
    background: #fff;
    border-radius: 5px;
    border: none;
    border: solid 2px rgb(242, 242, 242) !important;
    color: inherit;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
    width: 100%;
  }
}

select, textarea {
    /*-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;*/
    background: #fff;
    border-radius: 5px;
    border: none;
    border: solid 2px rgb(242, 242, 242) !important;
    color: inherit;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
    width: 100%;
  }

  input {
    &[type="text"]:invalid, &[type="password"]:invalid, &[type="email"]:invalid, &[type="tel"]:invalid {
      box-shadow: none;
    }
  }

  select:invalid, textarea:invalid {
    box-shadow: none;
  }

  input {
    &[type="text"]:focus, &[type="password"]:focus, &[type="email"]:focus, &[type="tel"]:focus {
      border-color: #5b6ba6;
    }
  }

  select:focus, textarea:focus {
    border-color: #5b6ba6;
  }

  .select-wrapper {
    text-decoration: none;
    display: block;
    position: relative;
    &:before {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    /*font-family: FontAwesome;*/
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
    color: rgba(255, 255, 255, 0.125);
    content: '\f078';
    display: block;
    height: 2.75em;
    line-height: 2.75em;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 2.75em;
  }
  select::-ms-expand {
    display: none;
  }
}

select option {
  color: #000;
  background: #fff;
}

input {
  &[type="text"], &[type="password"], &[type="email"] {
    height: 2.75em;
    border: 1px solid #f2f2f2;
    color: #2f2f2f;
  }
}

select {
  height: 2.75em;
  border: 1px solid #f2f2f2;
  color: #2f2f2f;
}

textarea {
  padding: 0.75em 1em;
  border: 1px solid #000;
  color: #2f2f2f;
}

input {
  &[type="checkbox"], &[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    display: block;
    float: left;
    margin-right: -2em;
    opacity: 0;
    width: 1em;
    z-index: -1;
  }
  &[type="checkbox"] + label, &[type="radio"] + label {
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    text-transform: none;
    letter-spacing: 0;
    font-weight: 300;
    padding-left: 2.4em;
    padding-right: 0.75em;
    position: relative;
  }
  &[type="checkbox"] + label:before, &[type="radio"] + label:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    /*font-family: FontAwesome;*/
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
  }
  &[type="checkbox"] + label:before, &[type="radio"] + label:before {
    background: rgba(255, 255, 255, 0.025);
    border-radius: 5px;
    border: solid 2px rgba(255, 255, 255, 0.125);
    content: '';
    display: inline-block;
    height: 1.65em;
    left: 0;
    line-height: 1.58125em;
    position: absolute;
    text-align: center;
    top: 0;
    width: 1.65em;
  }
  &[type="checkbox"]:checked + label:before, &[type="radio"]:checked + label:before {
    background: #ffffff;
    border-color: #ffffff;
    content: '\f00c';
    color: #2e3141;
  }
  &[type="checkbox"]:focus + label:before, &[type="radio"]:focus + label:before {
    border-color: #4c5c96;
  }
  &[type="checkbox"] + label:before {
    border-radius: 5px;
  }
  &[type="radio"] + label:before {
    border-radius: 100%;
  }
}

::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder, .polyfill-placeholder {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 1.0;
}

/* Box */

.box {
  border-radius: 5px;
  border: solid 2px rgba(255, 255, 255, 0.125);
  margin-bottom: 2em;
  padding: 1.5em;
  > :last-child {
    margin-bottom: 0;
    > :last-child {
      margin-bottom: 0;
      > :last-child {
        margin-bottom: 0;
      }
    }
  }
  &.alt {
    border: 0;
    border-radius: 0;
    padding: 0;
  }
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    /*font-family: 'Lato', sans-serif;*/
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
  }
  > .label {
    display: none;
  }
}

/* Image */

.image {
  border-radius: 5px;
  border: 0;
  display: inline-block;
  position: relative;
  img {
    border-radius: 5px;
    display: block;
  }
  &.left, &.right {
    max-width: 40%;
  }
  &.left img, &.right img {
    width: 100%;
  }
  &.left {
    float: left;
    padding: 0 1.5em 1em 0;
    top: 0.25em;
  }
  &.right {
    float: right;
    padding: 0 0 1em 1.5em;
    top: 0.25em;
  }
  &.fit {
    display: block;
    margin: 0 0 2em 0;
    width: 100%;
    img {
      width: 100%;
    }
  }
  &.main {
    display: block;
    margin: 0 0 3em 0;
    width: 100%;
    img {
      width: 100%;
    }
  }
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em;
  li {
    padding-left: 0.25em;
  }
}

ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em;
  li {
    padding-left: 0.5em;
  }
  &.alt {
    list-style: none;
    padding-left: 0;
    li {
      border-top: solid 1px rgba(255, 255, 255, 0.125);
      padding: 0.5em 0;
      &:first-child {
        border-top: 0;
        padding-top: 0;
      }
    }
  }
  &.icons {
    cursor: default;
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
      padding: 0 1em 0 0;
      &:last-child {
        padding-right: 0;
      }
      .icon:before {
        font-size: 1.25em;
      }
    }
  }
  &.actions {
    cursor: default;
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
      padding: 0 1em 0 0;
      vertical-align: middle;
      &:last-child {
        padding-right: 0;
      }
    }
    &.small li {
      padding: 0 0.5em 0 0;
    }
    &.vertical {
      li {
        display: block;
        padding: 1em 0 0 0;
        &:first-child {
          padding-top: 0;
        }
        > * {
          margin-bottom: 0;
        }
      }
      &.small li {
        padding: 0.5em 0 0 0;
        &:first-child {
          padding-top: 0;
        }
      }
    }
    &.fit {
      display: table;
      margin-left: -1em;
      padding: 0;
      table-layout: fixed;
      width: calc(100% + 1em);
      li {
        display: table-cell;
        padding: 0 0 0 1em;
        > * {
          margin-bottom: 0;
        }
      }
      &.small {
        margin-left: -0.5em;
        width: calc(100% + 0.5em);
        li {
          padding: 0 0 0 0.5em;
        }
      }
    }
  }
  &.contact {
    list-style: none;
    padding: 0;
    li {
      text-decoration: none;
      margin: 2.5em 0 0 0;
      padding: 0 0 0 2.25em;
      position: relative;
      color: #fff;
      a {
        color: #fff;
      }
      a:hover {
        background-color: transparent;
        color: #2296f3 !important;
      }
      &:before {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-transform: none !important;
        border-radius: 100%;
        border: solid 2px rgba(255, 255, 255, 0.125);
        display: inline-block;
        font-size: 0.8em;
        height: 2.5em;
        left: 0;
        line-height: 2.35em;
        position: absolute;
        text-align: center;
        top: 0;
        width: 2.5em;
      }
      &:first-child {
        margin-top: 0;
      }
    }
  }
  &.pagination {
    cursor: default;
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
      padding-left: 0;
      vertical-align: middle;
      > .page {
        -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        border-bottom: 0;
        border-radius: 5px;
        display: inline-block;
        height: 1.5em;
        line-height: 1.5em;
        margin: 0 0.125em;
        min-width: 1.5em;
        padding: 0 0.5em;
        text-align: center;
        &:hover {
          background-color: rgba(255, 255, 255, 0.025);
        }
        &.active {
          background-color: #4c5c96;
        }
      }
      &:first-child {
        padding-right: 0.75em;
      }
      &:last-child {
        padding-left: 0.75em;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  ul.actions {
    margin: 0 0 2em 0;
    li {
      padding: 1em 0 0 0;
      display: block;
      text-align: center;
      width: 100%;
      &:first-child {
        padding-top: 0;
      }
      > * {
        // width: 100%;
        margin: 0 !important;
      }
    }
    &.small li {
      padding: 0.5em 0 0 0;
      &:first-child {
        padding-top: 0;
      }
    }
  }
}

@media screen and (max-width: 736px) {
  ul.contact li {
    margin: 1.5em 0 0 0;
    text-align: left;
  }
}

@media screen and (max-width: 640px) {
  ul.pagination li {
    &:nth-child(n+2):nth-last-child(n+2) {
      display: none;
    }
    .button {
      width: 100%;
    }
    &:first-child {
      width: calc(50% - 2px);
      text-align: left;
      padding-right: 0.325em;
    }
    &:last-child {
      width: calc(50% - 2px);
      text-align: right;
      padding-left: 0.325em;
    }
  }
}

dl {
  margin: 0 0 2em 0;
  dt {
    display: block;
    font-weight: 600;
    margin: 0 0 1em 0;
  }
  dd {
    margin-left: 2em;
  }
}

/* Table */

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 2em 0;
  width: 100%;
  tbody tr {
    border: solid 1px rgba(255, 255, 255, 0.125);
    border-left: 0;
    border-right: 0;
    &:nth-child(2n+1) {
      background-color: rgba(255, 255, 255, 0.025);
    }
  }
  td {
    padding: 0.75em 0.75em;
  }
  th {
    color: #ffffff;
    font-size: 0.9em;
    font-weight: 600;
    padding: 0 0.75em 0.75em 0.75em;
    text-align: left;
  }
  thead {
    border-bottom: solid 2px rgba(255, 255, 255, 0.125);
  }
  tfoot {
    border-top: solid 2px rgba(255, 255, 255, 0.125);
  }
  &.alt {
    border-collapse: separate;
    tbody tr {
      td {
        border: solid 1px rgba(255, 255, 255, 0.125);
        border-left-width: 0;
        border-top-width: 0;
        &:first-child {
          border-left-width: 1px;
        }
      }
      &:first-child td {
        border-top-width: 1px;
      }
    }
    thead {
      border-bottom: 0;
    }
    tfoot {
      border-top: 0;
    }
  }
}

/* Button */

.main-submit {
  width: 350px;
  @media(max-width: 560px) {
    width: auto;
  }
}

input {
  &[type="submit"], &[type="reset"], &[type="button"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    // -moz-transition: background-color 0.2s ease-in-out;
    // -webkit-transition: background-color 0.2s ease-in-out;
    // -ms-transition: background-color 0.2s ease-in-out;
    // transition: background-color 0.2s ease-in-out;
    background-color: transparent;
    border-radius: 5px;
    border: 0;
    box-shadow: inset 0 0 0 2px rgba(47, 47, 47, 0.36);
    color: #ffffff !important;
    cursor: pointer;
    display: inline-block;
    /*font-family: Raleway, Helvetica, sans-serif;*/
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    font-weight: 700;
    height: 3.75em;
    letter-spacing: 0.1em;
    line-height: 3.75em;
    padding: 0 2.25em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    color: #000 !important;
  }
}

button, .button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  // -moz-transition: background-color 0.2s ease-in-out;
  // -webkit-transition: background-color 0.2s ease-in-out;
  // -ms-transition: background-color 0.2s ease-in-out;
  // transition: background-color 0.2s ease-in-out;
  background-color: transparent;
  border-radius: 5px;
  border: 0;
  box-shadow: inset 0 0 0 2px rgba(47, 47, 47, 0.36);
  color: #ffffff !important;
  cursor: pointer;
  display: inline-block;
  /*font-family: Raleway, Helvetica, sans-serif;*/
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8em;
  font-weight: 700;
  height: 3.75em;
  letter-spacing: 0.1em;
  line-height: 3.75em;
  padding: 0 2.25em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  color: #000 !important;
  text-decoration: none !important;

}

input {
  &[type="submit"]:hover, &[type="reset"]:hover, &[type="button"]:hover {
    /*background-color: rgba(255, 255, 255, 0.025);*/
    background-color: rgb(34, 150, 243);
    color: #fff !important;
  }
}

button:hover, .button:hover {
  /*background-color: rgba(255, 255, 255, 0.025);*/
  background-color: rgb(34, 150, 243);
  color: #fff !important;
}

input {
  &[type="submit"]:active, &[type="reset"]:active, &[type="button"]:active {
    background-color: rgba(255, 255, 255, 0.075);
  }
}

button:active, .button:active {
  background-color: rgba(255, 255, 255, 0.075);
}

input {
  &[type="submit"].icon:before, &[type="reset"].icon:before, &[type="button"].icon:before {
    margin-right: 0.5em;
    color: rgba(255, 255, 255, 0.35);
  }
}

button.icon:before, .button.icon:before {
  margin-right: 0.5em;
  color: rgba(255, 255, 255, 0.35);
}

input {
  &[type="submit"].special, &[type="reset"].special, &[type="button"].special {
    background-color: #4c5c96;
    box-shadow: none;
  }
}

button.special, .button.special {
  background-color: transparent;

  box-shadow: inset 0 0 0 1px rgba(47, 47, 47, 0.26);
}

input {
  &[type="submit"].special:hover, &[type="reset"].special:hover, &[type="button"].special:hover {
    background-color: #2296f3;
  }
}

button.special:hover, .button.special:hover {
  background-color: #2296f3;
}

input {
  &[type="submit"].special:active, &[type="reset"].special:active, &[type="button"].special:active {
    background-color: #2296f3;
  }
}

button.special:active, .button.special:active {
  background-color: #2296f3;
}

input {
  &[type="submit"].special.icon:before, &[type="reset"].special.icon:before, &[type="button"].special.icon:before {
    color: #7985b0;
  }
}

button.special.icon:before, .button.special.icon:before {
  color: #7985b0;
}

input {
  &[type="submit"].fit, &[type="reset"].fit, &[type="button"].fit {
    display: block;
    margin: 0 0 1em 0;
    width: 100%;
  }
}

button.fit, .button.fit {
  display: block;
  margin: 0 0 1em 0;
  width: 100%;
}

input {
  &[type="submit"].small, &[type="reset"].small, &[type="button"].small {
    font-size: 0.6em;
  }
}

button.small, .button.small {
  font-size: 0.6em;
}

input {
  &[type="submit"].big, &[type="reset"].big, &[type="button"].big {
    font-size: 1em;
  }
}

button.big, .button.big {
  font-size: 1em;
}

input {
  &[type="submit"] {
    &.disabled, &:disabled {
      opacity: 0.25;
    }
  }
  &[type="reset"] {
    &.disabled, &:disabled {
      opacity: 0.25;
    }
  }
  &[type="button"] {
    &.disabled, &:disabled {
      opacity: 0.25;
    }
  }
}

button {
  &.disabled, &:disabled {
    opacity: 0.25;
  }
}

.button {
  &.disabled, &:disabled {
    opacity: 0.25;
  }
}

@media screen and (max-width: 640px) {
  input {
    &[type="submit"], &[type="reset"], &[type="button"] {
      padding: 0 20px;
    }
  }
  button, .button {
    // padding: 0;
    padding: 0 10px;
  }
}

/* Features */

.features {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 2em 0;
  &.prices {
    -moz-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  article {
    padding: 1.75em 1.75em 0.1em 1.75em;
    /* background-color: #f2f2f2; */
    /*background-color: #2296f3;*/
    background-color: #ffffff;
    border-radius: 5px;
    // margin: 1.5em 3em 1.5em 0;
    margin: 1.5em 2em 1.5em 0;
    
    width: calc(50% - 1.5em);
    border: 1px solid #ccc;
  }
  &.prices article {
    margin: 0 37px;
  }
  .post .image {
    min-height: 600px;
    position: relative;
    border-radius: 0px 0px 0 0;
  }
  article {
    &:nth-child(2n) {
      margin-right: 0;
    }
    .image {
      border-radius: 5px 5px 0 0;
      display: block;
      margin-bottom: 1.75em;
      margin-left: -1.75em;
      margin-top: -1.75em;
      position: relative;
      width: calc(100% + 3.5em);
      overflow: hidden;
      background-color: #7c7c7c;
      padding: 0 !important;
      .player {
        height:100px;
        width:100px;
        position:absolute;
        z-index:2;
        left:50%;
        margin-left:-50px;
        top:50%;
        margin-top:-50px;
      }

      img:not(.player) {
        border-radius: 5px 5px 0 0;
        // width: 50%;
        transform: translate3d(0%, 0%, 0);
        filter: brightness(95%);
        margin: 0 auto;
        min-height: 267px;
        border-bottom: 1px solid #ccc;
        // max-height: 270px; 
        // max-height: 297px;
        @media(max-width: 1555px) {
          transform: translate3d(-7%, 0%, 0);
        }
        @media(max-width: 1280px) {
          transform: translate3d(-9%, 0%, 0);
        }
        @media(max-width: 876px) {
          transform: translate3d(-16%, 0%, 0);
        }
        @media(max-width: 756px) {
          transform: translate3d(-22%, 0%, 0);
        }
        @media(max-width: 740px) {
          transform: translate3d(-6%, 0%, 0);
          min-height: 188px;
          max-height: 188px;
        }
        @media(max-width: 640px) {
          min-height: 250px;
          transform: translate3d(0%, 0%, 0);
        }
        @media(max-width: 506px) {
          transform: translate3d(-7%, 0%, 0);
        }
        @media(max-width: 444px) {
          min-height: 230px;
        }
        @media(max-width: 415px) {
          transform: translate3d(-11%, 0%, 0);
        }
        @media(max-width: 363px) {
          transform: translate3d(-17%, 0%, 0);
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .features article {
    margin: 1em 2em 1em 0;
    width: calc(50% - 1em);
  }
}

@media screen and (max-width: 736px) {
  .features article {
    padding: 1.5em 1.5em 0.1em 1.5em;
    margin: 0.875em 1.75em 0.875em 0;
    width: calc(50% - 0.875em - 1px);
    .image {
      margin-bottom: 1.5em;
      margin-left: -1.5em;
      margin-top: -1.5em;
      width: calc(100% + 3em);
    }
  }
}

@media screen and (max-width: 640px) {
  .features {
    display: block;
    article {
      width: 100%;
      margin: 0 0 2em 0 !important;
    }
  }
  .inner .features .post {
    margin: auto !important;
  }
}

.blog-wrapper {
  @media(max-width: 740px) {
    h1 {
      margin-top: 15px !important;
    }
  }
  #four {
    margin: -0.5em 0;
    // margin-top: 3.5em;
    margin-top: 59px;
    .inner {
      padding: 3em 0 0em 0;
    }
    // h1.major {
    //   padding-bottom: 1.4em;
    // }
  }
}

#wrapper.post-wrapper  {
  @media(max-width: 1280px) {
    h1 {
      margin-top: -176px !important;
    }
    
  }
  @media(max-width: 980px) {
    h1 {
      margin-top: -100px !important;
    }
    margin-bottom: 6.1em !important;
  }
  @media(max-width: 810px) {
    h1 {
      margin-top: -50px !important;
    }
  }
  @media(max-width: 582px) {
    h1 {
      margin-top: 0 !important;
    }
  }
  @media(max-width: 832px) {
    .post .image {
      min-height: 400px !important;
    }
    @media(max-width: 582px) {
      // margin: 3.5em !important;
      margin: 4em 10px !important;
      .post .image {
        min-height: 300px !important;
      }
      // h1 {
      //   margin-top: -100px !important;
      // }
    }
    @media(max-width: 487px) {
      .post .image {
        min-height: 200px !important;
      }
      h1 {
        margin-top: -108px !important;
      }
    }
  }
  @media(max-width: 410px) {
    .post .image {
      min-height: 150px !important;
    }
  } 
}
.post-wrapper {
  margin: 5.5em !important;
  margin-bottom: 3.1em !important;
  .post {
    padding: 0 1.75em 0.1em 1.75em;
    box-shadow: 0px 0px 11px rgba(60, 26, 10, 0.4196078431372549);
    border: 0; 
    border-radius: 0;
    padding-bottom: 0;
    .image {
      margin-bottom: 0;
    }
  }
  .wrapper {
    margin-bottom: 0 !important;
  }
  .inner {
    padding: 0 !important;
    h2 {
      margin-bottom: 48px !important;
    }
  }
}

.tag-link {
  // margin: 2em 0.7em;
  margin: 1px;
  background-color: #2296f3;
  // border: 1px solid #ebebeb;
  // border-radius: 20px;
  // padding: 5px 20px;
  padding: 5px 10px;
  border-radius: 5px;
    display: inline-block;
  
  color: #fff !important;
  font-size: 15px;
  &:hover {
    cursor: pointer;
    color: #2296f3;
    background-color: #ebebeb;
  }
  @media(max-width: 640px) {
    font-size: 13px;
    margin: 5px 1px;
  }

}


.proposed {
  display: flex;
  width: 100%;
  // border: 1px solid red;
  // background-color: blue;
  overflow: hidden;
  flex-wrap: nowrap;
  article {
    // flex-direction: row;
    width: 32%;
    margin: 2px 5px !important;
    h4 a {
      padding: 0 !important;
      color: #5a5a5a !important;
      box-shadow: none;
      &:hover {
        color: #2296f3 !important;
        background-color: transparent !important;
      }
    }
  }
  @media(max-width: 890px) {
    display: block;
    article {
      margin-top: 20px !important;
      width: auto;
    }
  }
}

.blog-box {
  margin-bottom: 39px !important;

  margin-right: 5px;
  // margin-left: 5px; 

  
  h3 {
    // height: 108px;
    color: #2296f3;
    font-size: 25px;
    @media(max-width: 991px) {
      font-size: 24px !important;
    }
    @media(max-width: 640px) {
      height: auto !important;
      font-size: 20px !important;
    }
    a {
      background-color: transparent;
      box-shadow: none;
      color: #2296f3 !important;

      padding: 0 !important;
      &:hover {
        background-color: transparent;
        color: #2296f3 !important;
        text-decoration: none !important;

      }
    }   
  }
  p {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;  

 }
 a {
  background-color: transparent;
  color: rgba(47, 47, 47, 0.66) !important;

  padding: 0 2.25em !important;
  box-shadow: inset 0 0 0 1px rgba(47, 47, 47, 0.46);
  border-radius: 5px;
  &:hover {
    color: #fff !important;
    // border-radius: 10px;
    background-color: #2296f3;
    text-decoration: none !important;

  }
}

.image:hover {
  background-color: #7c7c7c !important;
}

}

.hide {
  display: none;
}

.show {
  display: block;
}



/* Header */
.logo-container {
  opacity: 0;
  position: absolute;
  // left: 1.3%;
  // top: 25px;
  top: 0;
      // padding-top: 15px !important;
      z-index: 10; 
      height: 4.5em;
      padding: 0 1.25em;
      line-height: 3.5em;

      img {
        vertical-align: middle;
      }
      .large-dev-logo {
        height: 34px;
        margin-left: 5px;
      }
      .small-dev-logo {
        height: 36px;
      }

    }


    .scroll.start {
      .first-li a{
        color: #000 !important;
      }
    }

    #header {
  /*-moz-transition: background-color 0.2s ease-in-out;*/
  /*-webkit-transition: background-color 0.2s ease-in-out;*/
  /*-ms-transition: background-color 0.2s ease-in-out;*/
  /*transition: background-color 0.2s ease-in-out;*/
  background-color: #fff;
  /*border-bottom: 2px solid #f2f2f2;*/
  box-shadow: rgba(0, 0, 0, 0.11765) 0 1px 3px;
  /*height: 3.5em;*/
  height: 4.5em;
  left: 0;
  line-height: 3.5em;
  padding: 0 1.25em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
  transition: height .6s ease-in-out;
  h1 {
    -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s;
    -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s;
    -ms-transition: opacity 0.2s ease-in-out, visibility 0.2s;
    transition: opacity 0.2s ease-in-out, visibility 0.2s;
    border-bottom: 0;
    font-size: 0.8em;
    margin-bottom: 0;
    opacity: 1;
    visibility: visible;
    a {
      border: 0;
    }
  }
  nav {
    /*font-family: Raleway, Helvetica, sans-serif;*/
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8em;
    font-weight: 700;
    height: 3em;
    letter-spacing: 0.1em;
    line-height: 3em;
    position: absolute;
    right: 0.7em;
    text-transform: uppercase;
    top: 0.7em;
    a {
      border: 0;
      display: inline-block;
      padding: 0 1em;
      &:before {
        float: right;
        margin-left: 0.75em;
      }
      &[href="#menu"] {
        text-decoration: none;
        -moz-transition: background-color 0.2s ease-in-out;
        -webkit-transition: background-color 0.2s ease-in-out;
        -ms-transition: background-color 0.2s ease-in-out;
        transition: background-color 0.2s ease-in-out;
        border-radius: 5px;
        /*box-shadow: inset 0 0 0 2px rgb(138, 138, 138);*/
        padding: 0 1.35em;
        color: #fff !important;
        display: none;
        &:before {
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-family: FontAwesome;
          /*font-family: 'Lato', sans-serif;*/
          font-style: normal;
          font-weight: normal;
          text-transform: none !important;
          content: '\f0c9';
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.025);
        }
        &:active {
          background-color: rgba(255, 255, 255, 0.075);
        }
      }
    }
  }
  &.alt h1 {
    opacity: 0;
    visibility: hidden;
  }
}

.scroll #header nav a[href="#menu"] {
  color: #2296f3 !important;
}

@media (max-width: 991px) {
  #header nav a[href="#menu"] {
    display: block !important;
    font-size: 1.6em;
    margin-top: 6px !important;
  }
  .scroll #header nav a[href="#menu"] {
    margin-top: 1px !important;
  }
}

@media (max-width: 725px) {
  #header nav a[href="#menu"] {
    margin-top: 10px !important;
  }
  .scroll #header nav a[href="#menu"] {
    margin-top: 12px !important;
  }
}

/*#header.alt {
		background-color: transparent;
		}*/

    @media screen and (max-width: 736px) {
      #header {
        height: 2.75em;
        line-height: 2.75em;
        nav {
          top: 0;
          right: 0;
      /*height: inherit;*/
      line-height: inherit;
      a {
        height: inherit;
        line-height: inherit;
        &[href="#menu"] {
          box-shadow: none;
          padding: 0 1em;
          border-radius: 0;
          &:hover, &:active {
            background-color: inherit;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  #header nav a[href="#menu"] {
    width: 4em;
    white-space: nowrap;
    text-indent: 4em;
    position: relative;
    &:before {
      font-size: 1.5em !important;
      width: inherit;
      position: absolute;
      top: 0;
      left: 0;
      text-indent: 0;
      text-align: right;
      margin-left: 0;
      padding-right: 1.25em;
      font-size: 25px;
    }
  }
  .scroll #header nav a[href="#menu"] {
    &:before {
      top: -3px;
    }
  }
}

/* Menu */

// #page-wrapper {
//   -moz-transition: -moz-filter 0.25s ease;
//   -webkit-transition: -webkit-filter 0.25s ease;
//   -ms-transition: -ms-filter 0.25s ease;
//   transition: filter 0.25s ease;
// }

#menu {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-pointer-events: none;
  -webkit-pointer-events: none;
  -ms-pointer-events: none;
  pointer-events: none;
  -moz-transition: opacity 0.35s ease, visibility 0.35s;
  -webkit-transition: opacity 0.35s ease, visibility 0.35s;
  -ms-transition: opacity 0.35s ease, visibility 0.35s;
  transition: opacity 0.35s ease, visibility 0.35s;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background: rgba(46, 49, 65, 0.8);
  cursor: default;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  text-align: center;
  top: 0;
  visibility: hidden;
  width: 100%;
  .inner {
    padding: 2.5em 1.5em 0.5em 1.5em;
    -moz-transform: translateY(0.5em);
    -webkit-transform: translateY(0.5em);
    -ms-transform: translateY(0.5em);
    transform: translateY(0.5em);
    -moz-transition: opacity 0.35s ease, -moz-transform 0.35s ease;
    -webkit-transition: opacity 0.35s ease, -webkit-transform 0.35s ease;
    -ms-transition: opacity 0.35s ease, -ms-transform 0.35s ease;
    transition: opacity 0.35s ease, transform 0.35s ease;
    -webkit-overflow-scrolling: touch;
    background: #2496f3;
    border-radius: 5px;
    display: block;
    max-width: 100%;
    opacity: 0;
    position: relative;
    width: 18em;
  }
  h2 {
    border-bottom: solid 2px rgba(255, 255, 255, 0.125);
    padding-bottom: 1em;
  }
  .close {
    background-image: url("../images/close.svg");
    background-position: 75% 25%;
    background-repeat: no-repeat;
    background-size: 2em 2em;
    border: 0;
    content: '';
    display: block;
    height: 4em;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    text-indent: 4em;
    top: 0;
    width: 4em;
  }
  .links {
    list-style: none;
    margin-bottom: 1.5em;
    padding: 0;
    li {
      padding: 0;
      a {
        border-radius: 5px;
        border: 0;
        display: block;
        /*font-family: Raleway, Helvetica, sans-serif;*/
        font-family: 'Open Sans', sans-serif;
        font-size: 0.8em;
        font-weight: 200;
        letter-spacing: 0.1em;
        line-height: 1.85em;
        padding: 0.75em 0;
        text-transform: uppercase;
        color: #fff;
        &:hover {
          background: #45558d;
        }
      }
    }
  }
}

#menu .links li a {
  color: #fff !important;
}

@media screen and (max-width: 736px) {
  #menu .inner {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .close {
      background-size: 1.5em 1.5em;
    }
  }
}

body.is-menu-visible {
  #page-wrapper {
    -moz-filter: blur(1.5px);
    -webkit-filter: blur(1.5px);
    -ms-filter: blur(1.5px);
    filter: blur(1.5px);
  }
  #menu {
    -moz-pointer-events: auto;
    -webkit-pointer-events: auto;
    -ms-pointer-events: auto;
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
    .inner {
      -moz-transform: translateY(0);
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}

/* Banner */
.enter-text {
  margin-left: 15px;
  margin-right: 15px;
  @media(max-width: 408px) {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}

.main-title {
  text-align: center;
  margin: 80px auto;
  /*margin-top: 130px;*/
  width: 71%;
  position: relative;
  top: -140px;
  h1,h2, p {
    text-transform: none !important;
    font-family: 'Open Sans', sans-serif !important;
  }
  @media(max-width: 506px) {
    top: -186px !important;
  }
}

@media (max-width: 1681px) {
  .main-title {
    top: -83px !important;
  }
}

.logo {
  a:hover {
    background-color: transparent;
  }
}


#banner:not(:root:root){
 background-attachment: initial !important;  

}


#banner {
  &:before {
    content: '';
    display: block;
    height: 1220px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(91, 106, 115, 0.58039);
  }
  /*padding: 12em 0 16.75em 0;*/
  padding: 21em 0 16.75em 0;
  background-color: #fff;
  background-image: url(../images/main.jpeg) !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;  
  background-position: center;
  margin-bottom: -4.5em;
  .inner {
    margin: 0 auto;
    width: 55em;
    /*width:79.4em;*/
    position: relative;
    top: -120px;
  }
  .logo {
    -moz-transition: opacity 2s ease, -moz-transform 1s ease;
    -webkit-transition: opacity 2s ease, -webkit-transform 1s ease;
    -ms-transition: opacity 2s ease, -ms-transform 1s ease;
    transition: opacity 2s ease, transform 1s ease;
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    margin: 0 0 1.3em 0;
    position: relative;
    z-index: 100;

    img {
      height: 50px;
      position: relative;
      top: -200px;
    }
  }
  h2 {
    -moz-transition: opacity 0.5s ease, -moz-transform 0.5s ease, -moz-filter 0.25s ease;
    -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease, -webkit-filter 0.25s ease;
    -ms-transition: opacity 0.5s ease, -ms-transform 0.5s ease, -ms-filter 0.25s ease;
    transition: opacity 0.5s ease, transform 0.5s ease, filter 0.25s ease;
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -moz-transition-delay: 0.65s;
    -webkit-transition-delay: 0.65s;
    -ms-transition-delay: 0.65s;
    transition-delay: 0.65s;
    -moz-filter: blur(0);
    -webkit-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    opacity: 1;
    /*border-bottom: solid 2px rgba(255, 255, 255, 0.125);*/
    font-size: 2.25em;
    margin-bottom: 0.8em;
    padding-bottom: 0.4em;
    position: relative;
    z-index: 99;
    top: -230px;
    color: #fff;
  }
  

  p {
    -moz-transition: opacity 2s ease, -moz-transform 0.5s ease, -moz-filter 0.25s ease;
    -webkit-transition: opacity 2s ease, -webkit-transform 0.5s ease, -webkit-filter 0.25s ease;
    -ms-transition: opacity 2s ease, -ms-transform 0.5s ease, -ms-filter 0.25s ease;
    transition: opacity 2s ease, transform 0.5s ease, filter 0.25s ease;
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    // -moz-transition-delay: 0.7s;
    // -webkit-transition-delay: 0.7s;
    // -ms-transition-delay: 0.7s;
    // transition-delay: 0.7s;
    -moz-filter: blur(0);
    -webkit-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    opacity: 1;
    font-family: Raleway, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: 200;
    /*letter-spacing: 0.1em;*/
    line-height: 2;
    text-transform: uppercase;
    position: relative;
    z-index: 99;
    top: -250px;
    color: #35363a;
  }
}

#banner .main-title h1 {
  transition: opacity 0.5s ease, transform 0.7s ease, filter 0.25s ease !important;
  transform: translateX(0) !important;
  transition-delay: 1s !important;
  filter: blur(0) !important;
  opacity: 0;
    /*border-bottom: solid 2px rgba(255, 255, 255, 0.125);*/
    font-size: 2.25em !important;
    margin-bottom: 0.8em !important;
    padding-bottom: 0.4em !important;
    position: relative;
    z-index: 99;
    top: -230px  ;
    color: #fff ;
    @media(max-width: 779px){
      font-size: 1.9em !important;
    }
    @media(max-width: 664px) {
      font-size: 1.7em !important;
    }
  }

  @media (max-width: 736px) {
    #banner:before {
      height: 1000px;
    }
  }

/*#banner .logo .icon {
		border-radius: 100%;
		border: solid 2px rgba(255, 255, 255, 0.125);
		cursor: default;
		display: inline-block;
		font-size: 2em;
		height: 2.25em;
		line-height: 2.25em;
		text-align: center;
		width: 2.25em;
	}
	*/

  @media screen and (max-width: 1280px) {
    #banner {
      padding: 30em 0 8.25em 0;
      background-color: #fff;
    /*background-image: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)), url("../../images/bg.jpg");*/
    /*background-size: auto, cover;*/
    /*background-position: center, center;*/
    margin-bottom: -6.5em;
  }
  .main-title {
    top: -220px !important;
  }
  #banner {
    .inner {
      top: -200px;
    }
    .logo img {
      top: -160px;
    }
    h2, p {
      top: -220px;
    }
  }
}

@media screen and (max-width: 980px) {
  /*#banner {
  	padding: 12em 3em 12.375em 3em;
  	margin-bottom: -4.75em;
  	}*/
    #banner .inner {
      width: 90%;
    }
  }

  @media screen and (max-width: 736px) {
  /*#banner {
  	padding: 11em 2em 4.25em 2em;
  	margin-bottom: -2.5em;
  	}*/
    #banner {
      .logo {
        margin: 0 0 1em 0;
        .icon {
          font-size: 1.5em;
        }
      }
      h2 {
        font-size: 1.5em;
      }
      p {
        font-size: 0.7em;
      }
    }
  }

  body.is-loading #banner {
    .logo {
      -moz-transform: translateY(0.5em);
      -webkit-transform: translateY(0.5em);
      -ms-transform: translateY(0.5em);
      transform: translateY(0.5em);
      opacity: 0;
    }
    h2 {
      opacity: 0;
      -moz-transform: translateX(0.25em);
      -webkit-transform: translateX(0.25em);
      -ms-transform: translateX(0.25em);
      transform: translateX(0.25em);
      -moz-filter: blur(2px);
      -webkit-filter: blur(2px);
      -ms-filter: blur(2px);
      filter: blur(2px);
    }
    p {
      opacity: 0;
      -moz-transform: translateX(0.5em);
      -webkit-transform: translateX(0.5em);
      -ms-transform: translateX(0.5em);
      transform: translateX(0.5em);
      -moz-filter: blur(2px);
      -webkit-filter: blur(2px);
      -ms-filter: blur(2px);
      filter: blur(2px);
    }
  }

/* Wrapper */

#wrapper > header {
  padding: 11em 0 2.25em 0;
  .inner {
    margin: 0 auto;
    width: 55em;
  }
  h2 {
    border-bottom: solid 2px rgba(255, 255, 255, 0.125);
    font-size: 2em;
    margin-bottom: 0.8em;
    padding-bottom: 0.4em;
  }
  p {
    /*font-family: Raleway, Helvetica, sans-serif;*/
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    font-weight: 200;
    letter-spacing: 0.1em;
    line-height: 2;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 1280px) {
  #wrapper > header {
    padding: 9em 0 6.25em 0;
    background-color: #2e3141;
    background-image: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)), url("../../images/bg.jpg");
    background-size: auto, cover;
    background-position: center, 0% 30%;
    margin-bottom: -6.5em;
  }
}

@media screen and (max-width: 980px) {
  #wrapper > header {
    padding: 11em 3em 7.375em 3em;
    background-size: auto, cover;
    background-position: center, 0% 0%;
    margin-bottom: -4.75em;
    .inner {
      width: 100%;
    }
  }
}

@media screen and (max-width: 736px) {
  #wrapper > header {
    padding: 6.5em 2em 3em 2em;
    background-size: auto, 125%;
    margin-bottom: -2.5em;
    h2 {
      font-size: 1.25em;
    }
    p {
      font-size: 0.8em;
    }
  }
}

.wrapper {
  background-color: #fff;
  margin: 6.5em 0;
  /*margin: 14.5em 0;*/
  position: relative;
}

.main-site .wrapper {
  margin: 0em 0 !important;
  &.spotlight .inner {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
}

.functions-wrapper .wrapper {
  background-color: #fff;
  margin: 14em 0;
  position: relative;
  @media(max-width: 640px){
    h1 {
      text-align: center !important;
      margin: 30px 0 !important;
    }
  }
}
h3 {
  font-size: 1.3em;
}

@media(max-width: 640px) {
  .functions-wrapper .inner {
    display: flex !important;
    flex-direction: column !important;
    a.image {
      order: 1;
    }
    .content {
      order: 0;
      margin-bottom: 30px;
    }
  }
}

@media(max-width: 640px) {
  #wrapper.functions-wrapper .wrapper #fun-img {
      margin: 0 0 1em 2em !important;

  }
}


@media (max-width: 935px) {
  .functions-wrapper .wrapper {
    margin: 0 0 !important;
  }
}

.main-site .spotlight .image {
  box-shadow: -2px 3px 12px 0px rgba(138, 138, 138, 0.41);
}

/*	.wrapper:before {
		content: "";
		display: block;
		height: 2px;
		width:100%;
		background-color: #2f2f2f;
		}*/

    .mainwrapper {
      &:before {
        background-image: url('../../images/white-triangle2.png');
        box-shadow: inset 0 -1px 0 0 #fff, 0 1px 0 0 #fff;
      }
      &:after {
        content: '';
        display: block;
        height: 1px;
        box-shadow: rgba(0, 0, 0, 0.11765) 0 1px 3px;
      }
    }

    .main-site .wrapper:after {
      content: '';
      display: block;
      height: 1px;
      box-shadow: rgba(0, 0, 0, 0.11765) 0 1px 3px;
    }

    .mainwrapper {
      &:before {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: '';
        display: block;
        height: 6.5em;
        position: absolute;
        width: 100%;
        left: 0;
        top: -6.5em;
      }
      &.alt:before {
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }

/*.mainwrapper:after {
	-moz-transform: scaleY(-1);
	-webkit-transform: scaleY(-1);
	-ms-transform: scaleY(-1);
	transform: scaleY(-1);
	bottom: -6.5em;
	left: 0;
	}*/

/*.mainwrapper.alt:after {
	-moz-transform: scaleY(-1) scaleX(-1);
	-webkit-transform: scaleY(-1) scaleX(-1);
	-ms-transform: scaleY(-1) scaleX(-1);
	transform: scaleY(-1) scaleX(-1);
	}*/

  .wrapper .inner {
    padding: 3em 0 1em 0;
    margin: 0 auto;
    width: 55em;
  }

  .main-site .wrapper .inner {
    // padding: 3em 0 3em 0 !important;
    padding: 3em 0.5em 3em 0.5em !important;
  }

  .functions-wrapper .wrapper {
    .inner {
      padding: 0;
      margin: 0 auto;
      width: 55em;
      overflow: hidden;
    }
    &.spotlight .image {
      margin: 0;
    }
    
  }

  .spotlight .inner a:hover {
    background-color: transparent !important;
  }



  .wrappermain {
    &.spotlight {
      background-color: #fff;
    }
    &.spolight.style {
      margin-top: 10em;
    }
    &.spotlight:before {
      background-image: url('../../images/white-triangle2.png');
      box-shadow: inset 0 -1px 0 0 #fff, 0 1px 0 0 #fff;
    }
  }

/*.wrappermain.spotlight:after {
	box-shadow: inset 0 -1px 0 0 #fff, 0 1px 0 0 #fff;
	}*/

  .wrapper.spotlight {
    .inner {
      display: -moz-flex;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -moz-align-items: center;
      -webkit-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -moz-flex-direction: row;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .image {
      border-radius: 100%;
      margin: 0 3em 2em 0;
      width: 40em;
      overflow: hidden;
      -ms-flex: 1;
    /*box-shadow: -2px 3px 12px 0px rgba(0,0,0,0.75);*/
    /*box-shadow: -2px 3px 12px 0px rgba(138, 138, 138, 0.41);*/
    img {
      border-radius: 100%;
      width: 100%;
      /*border: 1px solid #35363a;*/
    }
  }
  .content {
    /*width: 100%;*/
    -ms-flex: 2;
  }
}

.main-site .wrapper.spotlight .content {
  width: 100%;
}

.wrapper.spotlight:nth-child(2n - 1) {
  .inner {
    -moz-flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
  }
  .image {
    margin: 0 0 2em 3em;
  }
}

.functions-wrapper .wrapper.spotlight {
  &:nth-child(2n - 1) .image {
    margin: 0 0 -5em 3em !important;
  }
  .image {
    margin: 0 3em -4em 0 !important;
  }
}



@media screen and (max-width: 980px) {
  .wrapper {
    margin: 4.75em 0;
    &:before, &:after {
      height: 4.75em;
    }
    &:before {
      top: -4.75em;
    }
    &:after {
      bottom: -4.75em;
      left: 0;
    }
    .inner {
      padding: 3em 3em 1em 3em !important;
      width: 100% !important;
    }
    &.spotlight {
      .image {
        margin: 0 2em 2em 0;
        width: 32em;
      }
      &:nth-child(2n - 1) .image {
        margin: 0 0 2em 2em;
      }
    }
  }

  @media(max-width: 980px){
    #wrapper.functions-wrapper .inner {
      // padding: 0em 1em 0em 1em !important;
      padding: 0em 0.8em 0em 0.8em !important;
    }
  }
  			/*.functions-wrapper .wrapper.spotlight .image {
  				margin: 0 2em 2em 0 !important;
  				width: 80em !important;
  			}
  
  			.functions-wrapper .wrapper.spotlight:nth-child(2n - 1) .image { 
  				margin: 0 0 2em 2em !important;
  				}*/
        }

        @media(max-width: 640px){
          #wrapper.functions-wrapper .wrapper.spotlight:nth-child(2n - 1) .image {
            margin: 0 0 2em -1em !important;
          }
        }

        @media screen and (max-width: 736px) {
          .wrapper {
            margin: 2.5em 0 !important;
            &:before, &:after {
              height: 2.5em;
            }
            &:before {
              top: -2.5em;
            }
            &:after {
              bottom: -2.5em;
              left: 0;
            }
            .inner {
              // padding: 2em 1em 0.1em 1em !important;
              // padding: 2em 5px 0.1em 5px !important;
              padding: 2em 0.8em 0.1em 0.8em !important;
            }
            &.spotlight {
              .inner {
                -moz-align-items: -moz-flex-start;
                -webkit-align-items: -webkit-flex-start;
                -ms-align-items: -ms-flex-start;
                align-items: flex-start;
              }
              .image {
                width: 19em;
                margin: 0 1.75em 2em 0;
              }
              &:nth-child(2n - 1) .image {
                margin: 0 0 2em 1.75em;
              }
            }
          }

          .post-wrapper .inner {
            padding: 2em 10px 0.1em 10px !important;
          }
        }


        @media screen and (max-width: 640px) {
          .wrapper.spotlight {
            .inner {
              display: block;
            }
            .image {
              margin: 0 0 1em 0 !important;
              max-width: 85%;
              width: 12em;
            }
          }
          .functions-wrapper .wrapper.spotlight .image {
            margin: 0 3em 1em 0 !important;
          }
        }

        @media(max-width: 640px) {
          .functions-wrapper .inner {
            p {
              margin-bottom: 0;
            }
          }
        }

        @media screen and (max-width: 360px) {
          .wrapper .inner {
            padding: 2em 1.5em 0.1em 1.5em;
          }
        }

        .videos {
          margin: 0 0 1em 0 !important;
        }

/* Footer */
// .contact-wrapper {
//   margin-top: 3.5em !important;
//   @media(max-width: 980px) {
//     margin: 0 !important;
//     margin-top: 3.5em !important;
//   }
// }

@media (max-width: 740px) {
  .contact.footer {
    margin-top: 50px !important;
  }
}

#footer {
  // background-color: #f2f2f2;
  background-image: url('../images/footer2.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0 !important;
  // height: 100vh;
  .bg-filter {
    // background-color: rgba(0, 0, 0, 0.57);
    background-color: rgba(91, 106, 115, 0.58039);
    .inner {
      padding: 54px 0;
    }
    p, h2, label {
      color: #fff;
    }
    input[type="submit"] {
      color: #fff !important;
      border: 1px solid #fff;
    }
  }

}

.main-footer {
  form .field {
    margin: 0 0 2.5em 0;
  }
  @media(max-width: 740px) {
    h1 {
      margin-top: 15px !important;
    }
  }
}

#footer .inner {
  // padding: 5em 0 3em 0;
  padding: 0 !important;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 55em;
  > * {
    width: 100%;
  }
  form {
    margin: 0 3em 0 0;
    width: calc(50% - 1.5em);
  }
  .contact {
    //ZAKOMENTOWANE BO NIE CHODZI NA IE
    // width: calc(50% - 1.5em);
    width: 45%;
  }
  .copyright {
    border-top: solid 2px rgb(255, 255, 255);
    list-style: none;
    margin: 3em 0 0 0;
    padding: 2.6em 0 0 0;
    width: 100%;
    li {
      border-left: solid 2px rgba(255, 255, 255, 0.125);
      color: rgba(255, 255, 255, 0.35);
      display: inline-block;
      font-size: 0.9em;
      line-height: 1;
      margin-left: 1em;
      padding: 0;
      padding-left: 1em;
      color: #35363a;
      &:first-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
      }
      a {
        color: inherit;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  #footer {
    background-color: #f3f3f2;
    /* background-image: linear-gradient(to top, rgba(46, 49, 65, 0.8), rgba(46, 49, 65, 0.8)), url("../../images/bg.jpg"); */
    /* background-size: auto, cover; */
    /* background-position: center, center; */
    /*margin-top: -6.5em;*/
    padding-top: 6.5em;
  }
}

@media screen and (max-width: 980px) {
  #footer {
    margin-top: -4.75em;
    padding-top: 4.75em;
    .inner {
      padding: 3em 0.8em 1em 0.8em !important;
      display: block;
      width: 100%;
      form, .contact {
        width: 100%;
        margin: 0 0 4em 0;
      }
      .copyright {
        margin: 4em 0 2em 0;
      }

    }
  }
}

@media screen and (max-width: 736px) {
  #footer {
    margin-top: -2.5em;
    padding-top: 2.5em;
    .inner {
      padding: 2em 2em 0.1em 2em;
      form, .contact {
        margin: 0 0 3em 0;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  #footer .inner .copyright li {
    border-left: 0;
    display: block;
    margin: 1em 0 0 0;
    padding-left: 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 360px) {
  #footer .inner {
    padding: 2em 1.5em 0.1em 1.5em;
  }
    /*.start-form {
			flex-wrap: wrap;
			}*/
    }

/*#two::before {
	content: "";
	display: block;
	height: 5px;
	width: 100%;
	background: red;
	transform: rotate(5deg);
	}*/

/*************************************************/

.main-img {
  background-image: url(../images/screenshots/lolo_kalendarz.png);
  background-size: cover;
  background-repeat: no-repeat;
  /*width: 100%;*/
  width: 1376px;
  height: 762px;
  position: absolute;
  left: -80px;
  top: -120px;
  /*filter: brightness(60%);*/
  /*margin-top: 280px;*/
  border-radius: 9px;
  h3 {
    position: absolute;
    top: -65px;
    /*color: #2296f3;*/
    color: #fff;
    right: 0;
    left: 0;
    text-align: center;
    text-transform: none;
    font-size: 25px;
  }
  @media(max-width: 1124px ) {
    h3 {
      top: -87px;
    }
  }
}

// #form_save {
//   background-color: #fff;
//   color: #ccc;
//   // margin: 20px auto;
//   // float: right;
// }

// #form_save:hover {
//   background-color: #2296f3;

// }

.start-form {
  background-color: #8a8a8a;
  position: absolute;
  /*top: 60px;*/
  top: -280px;
  display: inline-flex;
  z-index: 999;
  /*background: #dedede9e;*/
  padding: 9px;
  border-radius: 9px;
  width: 100%;
  input {
    &[type="submit"] {
      height: 3.5em;
    }
    margin-left: 10px;
    background-color: #fff;
    color: #ccc;
  }
  select {
    margin-left: 10px;
    background-color: #fff;
    color: #ccc;
  }
  input {
    &[type="submit"] {
      background-color: #359af3;
      color: #fff !important;
      &:hover {
        background-color: rgb(243, 243, 242);
        color: #2296f3 !important;
      }
    }
    &::-webkit-input-placeholder {
      color: #ccc !important;
    }
  }
  @media(max-width: 840px) {
    top: -310px;
  }
  @media(max-width: 704px) {
    top: -325px;
    left: 0;
  }
  @media(max-width: 640px) {
    top: -370px;
  }
  @media(max-width: 560px) {
    top: -274px;
  }
}

@media (max-width: 631px) {
  .start-form input[type="submit"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

// Prices Tabs

.tab {
  overflow: hidden;
    // border: 1px solid #ccc;
    // background-color: #f1f1f1;
    display: inline-block;
    margin-bottom: 45px;
    @media(max-width: 835px) {
      margin: 10px auto;
    }
  }

  .tab button {
    background-color: #2296f3;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0 16px;
    transition: 0.3s;
    font-size: 17px;
    // margin: 0px 5px;
    font-weight: 100;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    text-transform: capitalize;
    color: #fff !important;
    min-width: 200px;
    @media(max-width: 483px) {
      margin-top: 10px !important;
      margin-bottom: 10px;
      min-width: 150px;
    }
    @media(max-width: 349px) {
      font-size: 15px;
    }
    @media(max-width: 329px) {
      min-width:140px;
    }

  }

  .month {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .year {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .tab button:hover {
    background-color: #f3f3f2;
    color: #2296f3 !important;
  }
  
  .tab button.activetab {
    background-color: #f3f3f2 !important;
    color: #2296f3 !important;
  }

  .tab button.active {
    background-color: #ccc;
    color: white !important;
  }

  .prices-disabled {
    display: none;
  }

  .promo-price{
    color: #f72121;
    font-size: inherit !important;
  }

  .prices-wrapper {
    .wrapper {
      // margin: 3.5em 0 !important;
      margin: 60px 0 !important;
      @media(max-width: 640px){
        h1 {
          text-align: center !important;
        }
      }
      @media(max-width: 991px) {
        h1 {
          padding-top: 0 !important;
          margin-top: -12px;
        }
      }
    }
    .inner {
      padding: 0;
    }
  }

  .prices {
    margin: 0 !important;
  }

  .features.prices article {
    h2 {
      color: #3a3c40;
      font-size: 40px;
    }
    h3.major {
      color: #3e9cf3;
      font-size: 20px;
      min-height: 67px;
    }
    h3 {
      font-size: 30px;
      color: #3a3c40;
    }
    @media(max-width: 1280px){
      p:first-of-type {
        height: 40px !important;
      }
    }
    @media(max-width: 980px){
      p:first-of-type {
        height: auto !important;
      }
    }
  }

  .prices article {
  /*border: 1px solid #2f2f2f !important;*/
  /*box-shadow: 7px 6px 18px #3b3d41b8;*/
  box-shadow: 4px 9px 23px #73737391;
}

.second-price {
  /*margin-right: 48px !important;*/
  margin-right: 37px !important;
}

// .features.prices article {
//   // transition: all .5s ease-in-out;
//   &:hover {
//     background-color: #419df3 !important;
//     h3, h2, p {
//       color: #fff !important;
//     }
//     button {
//       color: #fff !important;
//       background-color: #777777;
//     }
//   }
//   button:hover {
//     color: #fff !important;
//     background-color: #3bbf8d;
//   }
// }
// 

.support-title {
  @media(max-width: 980px) {
    margin-left: 15px !important;
  }
}

.prices-link {
  @media(max-width: 980px) {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.prices-wrapper .prices-link {
  a {
    color: #3e9cf3;
  }
  a:hover {
    background-color: transparent;
    color: #3a3c40;
  }
  @media(max-width: 980px) {
    position: relative;
    top: -70px;
  }
  @media(max-width: 640px) {
    top: -40px;
  }
} 


.search-box {
  // margin: 7em 13em -100px 13em;
  // border:1px solid red;
  margin-top: 5em;
  text-align: center;
  form {
    display: inline-block;
    width: 100%;
    padding: 10px;
    // background-color: rgba(0, 0, 0, 0.3);
    // border-radius: 9px;
    label {
      display: none;
    }
    #form {
      width: 100%;
      height: 2.75em;
      div {
        height: inherit;
        display: inline-block;
        text-align: center;
        @media(max-width: 408px) {
          width: 100% !important;
        }
      }
      div:first-of-type {
        width: 85%; 
        @media(max-width: 455px) {
          width:70%;
        }

      }
      div:last-of-type {
        width: 15%;
        text-align: right;
        @media(max-width: 455px) {
          width:30%;
        }
        @media(max-width: 408px) {
          text-align: center;
        }
      }
    }
  }
  #form_Szukaj {
    width: 100%;
    border: none !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: inherit;
    box-shadow: inset 0 0 0 1px rgba(47, 47, 47, 0.26) !important;

  }
  #form_Znajdź {
    box-shadow: inset 0 0 0 1px rgba(47, 47, 47, 0.26) !important;
    display: inline-block;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: #359af3;
    color: #fff !important;
    float: none;
    height: inherit;
    width:100%;
    padding: 0 1em;
    &:hover{
      background-color: #f3f3f2;
      color: #2296f3 !important;
    }
    @media(max-width: 408px) {
      margin-top: 20px;
      display: none;
    }
  }
}

.blog-wrapper .prices-link {
  a {
    color: #3e9cf3;
  }
  a:hover {
    background-color: transparent;
    color: #3a3c40;
  }
  @media(max-width: 980px) {
    top: 0 !important;
  }
}

@media (max-width: 1170px) {
  .features.prices {
    flex-wrap: wrap;
    justify-content: center;
    article {
      // margin-top: 50px;
      margin-bottom: 50px;
    }
    .last-box {
      margin-bottom: 7 !important;
      margin-left: 10px !important;
      margin-top: 10px !important;
    }
  }
}



.prices article {
  /*width: calc(50% - 12.5em);*/
  text-align: center;
  p {
    text-align: center;
    color: #5a5a5a;
    /* border-bottom: 1px solid #ccc; */
  }
  button {
    margin: 20px auto;
    /*border: 1px solid;*/
    padding: 0px 25px;
    background-color: #fff;
    color: #2296f3 !important;
    &:hover {
      background-color: #419df3;
      color: #fff !important;
    }
  }
  span {
    text-transform: lowercase;
    font-size: 12px;
    letter-spacing: 1px;
  }
}



@media(max-width: 991px) {
  .features.prices article.last-box {

    // margin-bottom: 30px !important;
  }

}
@media(max-width: 991px) {
  .features.prices article.last-box {
    margin-left: 10px !important;
    margin-right: 10px !important;

  }    
}
@media(max-width: 980px) {
  .features.prices article.last-box {
    // margin-left: 0 !important;
  }
  .features.prices article {
    width: 70% !important;

  }

}
@media(max-width: 640px) {
  .features.prices article {
    width: 100% !important;
    margin-left: 0 !important;

  }    
  .features.prices article.last-box {
    margin-left: 0 !important;

  }
}
// @media(max-width: 560px) {
//   .features.prices article.last-box {
//     margin-left: 20px !important;
//   }  
// }

@media (max-width: 968px) {
  .prices {
    /*display: block;*/
    article {
      width: calc(100% - 12.5em);
      margin: 30px auto !important;
    }
    .last-box {
      margin: 30px 0 !important; 
    }
  }
  .second-price {
    margin-right: 100px !important;
  }
}

@media(max-width: 485px) {
  .features.prices article {
    // margin-left: 0 !important;
    width: 100% !important;
  }
}

// .small-dev {
//   display: none;
// }

@media (max-width: 560px) {
  #banner {
    padding: 30em 0 0.25em 0 !important;
  }   
  .prices article {
    width: calc(100% - 2.5em);
  } 
  .start-form {
    display: flex;
    flex-wrap: wrap;
    /*top: -22px;*/
    input, select {
      margin: 8px auto;
    }
  }
  .main-img {
    /*top: 70px;*/
    display: none !important;
  }
  .small-dev {
    display: block;
    color: #fff;
    text-align: center;
    font-size: 17px;
  }
  .style1 {
    margin-top: 12em !important;
  }
}

/*@media(max-width: 422px) {
			.start-form {
				top: 8px;
			}
			}*/

      .functions-wrapper {
        margin: 8em 0;
        p {
          text-align: justify;
        }
      }

/*Functions images*/

.zoom-img .image {
  width: 130em !important;
  height: 22em;
  border-radius: 0 !important;
  img {
    width: auto !important;
    border-radius: 0 !important;
  }
  .magnifier {
    background-repeat: no-repeat;
    position: absolute;
    z-index: 100;
    border-radius: 50%;
    box-shadow: 1px 2px 22px #9f9fa2e0;
    width: 190px;
    height: 190px;
    zoom: 105%;
  }
  .img-base {
    background-image: url(../images/screenshots/lolo_klienci.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 100%;
    position: relative;
    .magnifier-base {
      background-image: url(../images/screenshots/lolo_klienci-circle.png);
      z-index: 100;
      top: 10%;
      left: 16%;
      background-position: 9% 20%;
    }
  }
  .img-raport {
    background-image: url(../images/screenshots/lolo_raport.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 100%;
    position: relative;
    .magnifier-raport {
      background-image: url(../images/screenshots/lolo_raport-circle.png);
      background-position: right top;
      top: 25%;
      left: 26%;
      background-position: 47% 48%;
    }
  }
  .img-sales {
    background-image: url(../images/screenshots/lolo_sprzedaz1.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 100%;
    position: relative;
    .magnifier-sales {
      background-image: url(../images/screenshots/lolo_sprzedaz1-circle.png);
      top: 21%;
      left: 27%;
      background-position: 70% 23%;
    }
  }
  .img-services {
    background-image: url(../images/screenshots/lolo_cennik_online.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 100%;
    position: relative;
    .magnifier-services {
      background-image: url(../images/screenshots/lolo_cennik_online-circle.png);
      background-size: 200%;
      top: 21%;
      left: 0%;
    }
  }
  .img-calendar {
    background-image: url(../images/screenshots/lolo_kalendarz.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 100%;
    position: relative;
    .magnifier-calendar {
      background-image: url(../images/screenshots/lolo_kalendarz-circle.png);
      background-position: right bottom;
      top: 14%;
      left: 39%;
    }
  }
  .img-personalization {
    background-image: url(../images/screenshots/lolo_personalizacja.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 100%;
    position: relative;
    .magnifier-personalization {
      background-image: url(../images/screenshots/lolo_personalizacja-circle.png);
      top: 21%;
      left: 0;
    }
  }
  .img-discounts {
    background-image: url(../images/screenshots/lolo_rabaty.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 100%;
    position: relative;
    .magnifier-discounts {
      background-image: url(../images/screenshots/lolo_rabaty-circle.png);
      top: 4%;
      left: 0%;
      background-position: 6% 47%;
    }
  }
  .img-schedule {
    background-image: url(../images/screenshots/lolo_grafik_pracy.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 100%;
    position: relative;
    .magnifier-schedule {
      background-image: url(../images/screenshots/lolo_grafik_pracy-circle.png);
      top: 24%;
      left: 17%;
    }
  }
  .img-management {
    background-image: url(../images/screenshots/lolo_zarzadzanie_pracownikami.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 100%;
    position: relative;
    .magnifier-management {
      background-image: url(../images/screenshots/lolo_zarzadzanie_pracownikami-circle.png);
      top: 16%;
      left: 15%;
    }
  }
  .img-more {
    background-image: url(../images/screenshots/lolo_zebatka.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 100% !important;
    position: relative;
    .magnifier-more {
      background-image: url(../images/screenshots/lolo_zebatka-circle.png);
      top: 2%;
      left: 56%;
      background-position: left;
    }
  }
  .img-warehouse {
    background-image: url(../images/screenshots/lolo_magazyn.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 100%;
    position: relative;
    .magnifier-warehouse {
      background-image: url(../images/screenshots/lolo_magazyn-circle.png);
      top: 10%;
      left: 4%;
      background-position: 13% 8%;
    }
  }
  .img-laptop {
    background-image: url(../../images/laptop.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    height: 100%;
    position: relative;
  }
  .img-booking {
    background-image: url(../images/screenshots/booking.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    height: 94%;
    position: relative;
    .magnifier-booking {
      background-image: url(../images/screenshots/lolo_bookingOnline2-circle.png);
      top: 19%;
      left: 0%;
      background-position: 0% 64%;
    }
  }
  .img-barcode {
    background-image: url(../../images/barcode.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    height: 100%;
    position: relative;
  }
}

@media (max-width: 991px) {
  .zoom-img .image .magnifier {
    width: 150px;
    height: 150px;
  }
}

/*@media(max-width: 803px) {
	.zoom-img .image  .magnifier {
		width: 100px;
		height: 100px;
	}
	.magnifier-calendar {
		background-position: center right !important;
	}
	.magnifier-booking {
		background-position: center top !important;
	}
	.magnifier-discounts {
		background-position: center center !important;
	}
	}*/

  @media (max-width: 803px) {
    .zoom-img .image .magnifier {
      display: none;
    }
  }

  @media (max-width: 499px) {
    .img-box {
      background-size: cover !important;
      height: 70% !important;
    }
  }

  @media (max-width: 640px) {
    .zoom-img .image .img-laptop {
      background-size: cover !important;
      height: 76%;
    }
  }

  @media (max-width: 640px) {
    .zoom-img .image .img-barcode {
      background-size: cover !important;
    }
  }

/*MENU LIST*/

#main-menu .menu-ul {
  display: inline-block;
  position: fixed;
  z-index: 20000;
  right: 20px;
  /*margin: 25px;*/
  top: 0;
  overflow: hidden;
  li {
    display: inline-block;
    float: left;
    text-transform: uppercase;
    a {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .first-li {
    a:hover {
      padding: 7px 15px;
      border-radius: 10px;
    }
  }
}

@media (max-width: 1160px) {
  #main-menu .menu-ul li {
    font-size: 13px !important;
  }
}

.last:hover {
  background-color: transparent !important;
  border-bottom: transparent !important;
}

#main-menu .menu-ul li {
  &:nth-last-child(2) {
    margin-left: 100px;
    margin-right: 20px;
  }
  &:nth-last-child(1), &:nth-last-child(2) {
    padding: 2px 8px;
    margin-top: -5px;
    box-shadow: inset 0 0 0 2px rgba(47, 47, 47, 0.27);
    border-radius: 25px;
    background: transparent;
  }
  &:hover {
    &:nth-last-child(1), &:nth-last-child(2) {
      background-color: #f3f3f2;
      
    }
  }
}

#main-menu .menu-ul .last-li:hover .last {
  color: #2296f3 !important;
}

@media(max-width: 1144px){
  .last-li {
    margin-top: 16px !important;
  }
  .first-li {
    padding-top: 18px !important;
  }
}

#page-wrapper.scroll #main-menu .menu-ul .last-li:hover .last {
  color: #2296f3  !important;
}

/*#main-menu .menu-ul li a:hover:nth-last-child(1), #main-menu .menu-ul li a:hover:nth-last-child(2) {
	
	background-color: transparent !important;
	}*/

  @media (max-width: 991px) {
    #main-menu {
      display: none;
    }
  }

  .search-posts {
    width: 50%;
    text-align: center;
    margin: 20px auto;
    input[type="submit"]{
      color: rgba(47, 47, 47, 0.66) !important;
      margin-top: 20px;
      box-shadow: inset 0 0 0 1px rgba(47, 47, 47, 0.26);
      &:hover {
        color: #fff !important;
      }
    }
  }